@import './themes/index.css';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    @font-face {
        font-family: Inter-Bold;
        src: url(../public/fonts/Inter-Bold.ttf);
    }

    @font-face {
        font-family: Inter-Light;
        src: url(../public/fonts/Inter-Light.ttf);
    }

    @font-face {
        font-family: Inter-Medium;
        src: url(../public/fonts/Inter-Medium.ttf);
    }

    @font-face {
        font-family: Inter-Regular;
        src: url(../public/fonts/Inter-Regular.ttf);
    }

    @font-face {
        font-family: Inter-SemiBold;
        src: url(../public/fonts/Inter-SemiBold.ttf);
    }

    @font-face {
        font-family: Inter-Thin;
        src: url(../public/fonts/Inter-Thin.ttf);
    }

    @font-face {
        font-family: OpenSans-Bold;
        src: url(../public/fonts/OpenSans-Bold.ttf);
    }

    @font-face {
        font-family: OpenSans-Regular;
        src: url(../public/fonts/OpenSans-Regular.ttf);
    }

    @font-face {
        font-family: OpenSans-Medium;
        src: url(../public/fonts/OpenSans-Medium.ttf);
    }

    @font-face {
        font-family: OpenSans-SemiBold;
        src: url(../public/fonts/OpenSans-SemiBold.ttf);
    }

    @font-face {
        font-family: Roboto-Regular;
        src: url(../public//fonts/Roboto-Regular.ttf);
    }

    @font-face {
        font-family: Roboto-Medium;
        src: url(../public//fonts/Roboto-Medium.ttf);
    }

    @font-face {
        font-family: Roboto-Bold;
        src: url(../public//fonts/Roboto-Bold.ttf);
    }
}

/* GLOBAL OVERRIDES */
:root {
    --color-page-bg: linear-gradient(180deg, #E6FAFF 0%, #FFF 100%);
}

body {
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root .ant-layout {
    overflow-y: auto;
    background: var(--color-page-bg);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar{
    display: none
}

#root .ant-radio-wrapper .ant-radio-inner {
    width: 18px;
    height: 18px;
}

#root .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: #fff;
    border-width: 2px;
}

#root .ant-radio-wrapper .ant-radio-inner::after {
    background-color: var(--color-primary);
    width: 24px;
    height: 24px;
    margin-block-start: -12px;
    margin-inline-start: -12px;
}

p {
    margin: 0;
}

.bg-gradient {
    background: linear-gradient(180deg, #E6FAFF 0%, #FFF 100%);
}

.ant-card-body {
    padding: 0 !important;
}
.ant-btn {
    height: auto !important;
}

.ant-form-item {
    margin-bottom: 14px !important;
}

.ant-notification-notice-message {
    display: none !important;
}

.eligibility-card-line {
    background: linear-gradient(270deg, rgba(1, 71, 81, 0.00) -2.03%, rgba(1, 71, 81, 0.20) 50.58%, rgba(1, 71, 81, 0.00) 100%);
}

.ant-tooltip-inner {
    color: #014751 !important;
    font-size: 12px !important;
}
form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
    display: none;
}

form div.ant-form-item-explain {
    font-size: 12px;
}

.ant-radio-wrapper .ant-wave-target {
    align-self: unset !important;
    position: relative;
    top: 4px;
}

.ant-radio-wrapper-checked span div {
    color: #3D494B !important;
    font-weight: bold !important;
}

.ant-select-dropdown {
    padding: 0 !important;
}

.ant-select-selection-placeholder {
    padding-left: 6px !important;
    color: #657173 !important;
    font-size: min(3.333vw,14.32px) !important;
}

.ant-select-selection-item {
    position: relative !important;
    top: 10px !important;
}

.ant-select-changed .ant-select-arrow {
    top: 40px !important;
}

.custom-date-picker .ant-picker-suffix svg{
    width: 16px !important;
    height: 16px !important; 
    color: #657173 !important;
}
.hight-dvh {
    height: 100vh;
    height: 100dvh;
}
.ant-notification 
.ant-notification-notice-wrapper 
.ant-notification-notice-close {
    right: 8px !important;
}
.hideScroll .modalpage {
  display: none !important;
}
.hideScroll #checkoutmodal {
  display: none !important;
}