.shimmer-container {
    background-color: gray;
    position: relative;
    height: 132px;
}
.shimmer {
    height: 100%;
    width: 100%;
    background-color: gray;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.animate {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}
@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}